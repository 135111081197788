.form {
    max-width: 800px;
    width: 100%;
    .row {
        display: flex;
        border: 1px solid #000;
        margin: 5px 0;
    }
    .row-editor {
        display: flex;
        flex-direction: column;
    }
    .editor {
        margin: 15px;
    }
}
#sidebar-container {
    .wrapper {
        transform: translateX(0)!important;
    }
}

a {
    cursor: pointer;
    text-decoration: underline;
}

.head-info {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

@import './components/input';
@import './components/button';
@import './components/textarea';
