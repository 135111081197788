.textarea-wrapper {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 15px;
    .textarea {
		height: 100%;
		resize: none;
		outline: none;
		min-height: 70px;
		max-height: 70px;
		overflow: auto; 
        border: 1px solid #d2d2d2;
        padding: 10px 15px;
    }
}