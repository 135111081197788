.btn-wrapper {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 15px;
    input {
        max-width: 100%;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid #d2d2d2;
        padding: 10px 15px;
        cursor: pointer;
    }
}