@import url('https://fonts.cdnfonts.com/css/helvetica-neue-lt-pro');

#sidebar-container {
    display: block!important;
    z-index: 99999999999999;
}

#sidebar-container * {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue LT Pro', 'sans-serif';
    box-sizing: content-box;
    line-height: 1.2;
}
#sidebar-container {
    position: fixed;
    top: 50%;
    right: 0;
    max-width: 400px;
    width: 100%;
    transform: translateY(-50%);
    z-index: 9999999!important;
}

#sidebar-container .wrapper {
    display: flex;
    transform: translateX(calc(100% - 60px));
    transition: .6s;
}
#sidebar-container .wrapper:hover {
    transform: translateX(0);
}

#sidebar-container .wrapper.active {
    transform: translateX(0);
}

#sidebar-container .visible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    right: 0;
    background-color: #78a290;
    color: #ffffff;
    /* transform: rotate(-90deg); */
    max-width: 40px;
    min-width: 40px;
    max-height: 270px;
    width: 100%;
    padding: 15px 10px;
    text-decoration: none;
}

#sidebar-container .visible p {
    writing-mode: vertical-rl;
    font-size: 18px;
    transform: rotate(180deg);
} 

#sidebar-container .visible .svg svg {
    fill: #ffffff;
}

#sidebar-container .visible .arrow-svg svg {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
}

#sidebar-container .visible .cash-svg svg {
    width: 100%;
    height: 50px;
}

#sidebar-container .wrapper .hidden {
    display: block!important;
    max-width: 305px;
    /* min-width: 305px; */
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
    padding: 15px 20px 15px 15px;
    max-height: 320px;
    height: 320px;
    overflow-y: auto;
}

#sidebar-container .wrapper .hidden::-webkit-scrollbar {
    width: 6px;
}

#sidebar-container .wrapper .hidden::-webkit-scrollbar-track {
    background: #ffffff;
}

#sidebar-container .wrapper .hidden::-webkit-scrollbar-thumb {
    background: #78a290;
}

#sidebar-container .wrapper .hidden::-webkit-scrollbar-thumb:hover {
    background: #78a290;
}

#sidebar-container .wrapper .hidden .header {
    display: flex;
    flex-direction: column;
}

#sidebar-container .wrapper .hidden .header .logo svg {
    fill: #39637b;
    width: 180px;
    height: 32px;
}

#sidebar-container .wrapper .hidden .header .row {
    display: flex;
    margin-top: 20px;
}

#sidebar-container .wrapper .hidden .header .row .svg {
    margin-left: 15px;
}

#sidebar-container .wrapper .hidden .header .row .svg svg {
    width: 64px;
    height: 72px;
    fill: #39637b;
}

#sidebar-container .wrapper .hidden .header .row .desc {
    margin-left: 15px;
}

#sidebar-container .wrapper .hidden .header .row .desc p {
    color: #74726b;
    font-size: 14px;
}

#sidebar-container .wrapper .hidden .faq {
    margin-top: 5px;
}

#sidebar-container .wrapper .hidden .faq .row {
    margin-bottom: 10px;
}

#sidebar-container .wrapper .hidden .faq .row.active .header-faq .arrow svg {
    transform: rotate(-90deg);
}

#sidebar-container .wrapper .hidden .faq .row .header-faq {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 10px;
    margin-bottom: 7px;
    cursor: pointer;
}

#sidebar-container .wrapper .hidden .faq .row .header-faq h3 {
    color: #39637b;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}

#sidebar-container .wrapper .hidden .faq .row .header-faq .arrow svg {
    width: 15px;
    height: 15px;
    transform: rotate(90deg);
    fill: #39637b;
    transition: .3s;
}

#sidebar-container .wrapper .hidden .faq .row.active .content h4:not(:first-of-type) {
    margin-top: 20px;
}

#sidebar-container .wrapper .hidden .faq .row .content h1,h2,h3,h4,h5 {
    font-size: 14px;
    color: #74726b;
    font-weight: 500;
}

#sidebar-container .wrapper .hidden .faq .row .content {
    transition: .3s;
    height: 0;
    opacity: 0;
    visibility: hidden;
}

#sidebar-container .wrapper .hidden .faq .row.active .content {
    height: initial;
    opacity: 1;
    visibility: visible;
}

#sidebar-container .wrapper .hidden .faq .row .content p {
    font-size: 14px;
    color: #74726b;
}

#sidebar-container .wrapper .hidden .navigation a {
    font-size: 14px;
    text-decoration: none;
    color: #74726b;
}

#sidebar-container .wrapper .hidden .navigation a svg {
    width: 8px;
    height: 8px;
    margin-left: 3px;
    margin-right: 6px;
}

@media( max-width: 500px ) {
/*     
    #sidebar-container .visible p {
        display: none;
    } */
    
    #sidebar-container .visible {
        /* max-height: 45px; */
        min-width: initial;
        padding: 10px 5px;
    }

    /* #sidebar-container .visible .arrow-svg {
        display: none;
    } */
    
    #sidebar-container .wrapper {
        display: flex;
        justify-content: flex-end;
        transform: translateX(calc(100% - 50px));
        transition: .6s;
    }

    #sidebar-container .wrapper .hidden {
        /* max-height: 335px; */
        min-height: 335px;
        max-height: 100vh;
        min-height: initial;
        height: initial;
    }
}

@media( max-width: 380px ) {
    #sidebar-container .wrapper .hidden .navigation a {
        font-size: 12px;
    }
}